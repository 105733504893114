<template>
  <div class="home">
    <el-main>
      <el-container>
        <div class="container">
          <h1 class="title">Privacy Policy</h1>
          <p>Last revised: May 24, 2023</p>
          <p>
            Your privacy is important to us. This Privacy Policy (the “Privacy
            Policy”) governs the use of your Personal Information (as defined
            below) in connection with any websites, online services, and/or
            applications owned or operated by Brightsight Media Inc, (“we,” “us,” “our,” or “privateautoquote.com”),
            including, without
            limitation, in connection with:
          </p>
          <div style="margin-bottom: 15px">
            <p>
              - the websites currently located at
              <a target="_blank" href="/">FindMedicareChoice.com</a>
              and any other website owned by us upon which this Privacy Policy
              is linked (collectively, the “Site”); and
            </p>
            <p>
              - any other services, transactions, engagements, or other
              interactions you have with us (all of which are collectively
              referred to as the “Services”).
            </p>
          </div>
          <p>
            This Privacy Policy explains what information we collect about you,
            how we use or disclose such information, how to exercise any rights
            you may have with respect to your Personal Information (including
            any rights to opt-out of certain uses of your information), and the
            safeguards we have in place to reduce the risk of unauthorized
            access and use.
          </p>
          <p>
            Please carefully read this Privacy Policy, which is part of our
            <a href="/terms-of-use">Terms of Use</a>, before using our Site
            and/or Services. If you do not agree with the
            <a href="/terms-of-use">Terms of Use</a>
            and/or the Privacy Policy, you must refrain from using our Site
            and/or Services. By accessing or using our Site/Services you agree
            to our use of your information consistent with the
            <a href="/terms-of-use">Terms of Use</a>
            and this Privacy Policy.
          </p>
          <p><b> 1. INSURANCE DISCLAIMER </b></p>
          <p>
            We are not an insurance company or an insurance agent. We help
            connect individuals and businesses with companies that may be able
            to provide insurance products. We do not charge individuals or
            businesses to submit inquiries regarding obtaining insurance through
            our Site/Services, instead, we receive a payment from the companies
            with whom we connect potential buyers such as insurance agents,
            insurers, and third-party insurance service websites (such websites
            and their operators referred to herein as “Insurance Services”).
          </p>
          <p><b> 2. PRIVACY POLICY CHANGES </b></p>
          <p>
            This Privacy Policy is subject to change. We encourage you to review
            this Privacy Policy frequently for any revisions or amendments. Such
            changes to this Privacy Policy will be posted on the Site and will
            be effective immediately upon posting. You will be deemed to have
            been made aware of and have accepted the changes by your continued
            use of the Site or Services.
          </p>
          <p><b> 3. INFORMATION WE COLLECT&nbsp;</b></p>
          <p><b> a. Personal Information </b></p>
          <p>
            When you view content on our website, we automatically collect PII
            about your computer hardware and software. This information can
            include your IP address, browser type, domain names, internet
            service provider (ISP), the files viewed on our site (e.g., HTML
            pages, graphics, etc.), operating system, clickstream data, access
            times and referring website addresses. We use this information to
            provide general statistics regarding use of the Websites. For these
            purposes, we do link this automatically-collected data to other
            Personal Information such as name, email address, address, and phone
            number.
          </p>
          <p>
            We collect information that personally identifies, relates to,
            describes, or is capable of being associated with you (“Personal
            Information”), including:
          </p>
          <ul>
            <li>
              Personal Identifiers (e.g., name, date of birth, social security
              number, driver’s license number, IP address, login username &amp;
              password)
            </li>
            <li>
              Characteristics of Protected Classifications (e.g., gender, sex,
              marital status, sexual orientation)
            </li>
            <li>
              Contact Information (e.g., mailing address, email address, phone
              number, fax number)
            </li>
            <li>
              Commercial Information (e.g., motor vehicles owned/leased, use of
              motor vehicles, residence information, insurance claim history,
              desired insurance products)
            </li>
            <li>
              Financial Account Information (e.g., credit card number, checking
              account number)
            </li>
            <li>
              Consumer Reports (e.g., criminal and driving history reports,
              credit reports)
            </li>
            <li>
              Internet or Other Electronic Network Activity (e.g., browsing
              history, search history, other information regarding your use of
              our Site/Services that can be associated with you)
            </li>
            <li>
              Professional or Employment-Related Information (e.g., occupation)
            </li>
            <li>
              Education Information (e.g., highest level of education obtained)
            </li>
            <li>Health Information (e.g., tobacco use, medical history)</li>
            <li>
              Device, browser, and location information, such as computer make
              and model, browser type and version, IP address (to determine
              geographic location for marketing purposes, in compliance with
              applicable laws).
            </li>
          </ul>
          <p>
            This information can be provided to us by you through our websites
            or webforms an/or information we collect automatically when you use
            our websites and webforms.
          </p>
          <p>
            Inferences drawn from the above categories of Personal Information
            that relate to your preferences, characteristics, psychological
            trends, predispositions, behavior, attitudes, intelligence,
            abilities, and aptitudes.
          </p>
          <p>
            The types of Personal Information we collect about you may vary
            based on how you use the Site and/or Services and your role in the
            insurance marketplace (e.g., consumer, agent, insurer).
          </p>
          <p>
            From time to time, and in order to provide you with the best service
            possible, we may use your information for automated decision making.
          </p>
          <p><b> b. Consumer Report Notice </b></p>
          <p>
            In some cases, insurance agents, insurers, and Insurance Services
            may need to obtain additional background information about you. For
            example, consumer reports (including credit reports) may be ordered
            in connection with your insurance application. These reports provide
            information that may assist in determining your eligibility for
            insurance and the price you may be charged. Consumer reports may
            also be used for updates, renewals, or extensions of insurance.
            Insurance companies may also obtain and use a credit-based insurance
            score based on information contained in your consumer report.
            Insurance agents, insurers, and Insurance Services may use a
            third-party service provider in connection with the development of
            your insurance score and rate quote. By providing information to us
            for use in obtaining an insurance quote, you give your express
            consent for insurance agents, Insurers, Insurance Services, and
            associated third-party service providers with whom we share your
            Personal Information to obtain and use a consumer report as
            described in this paragraph.
          </p>
          <p><b> c. Usage Data </b></p>
          <p>
            We automatically collect information in connection with the actions
            you take on the Site and in connection with using the Services
            (“Usage Data”). For example, each time you use the Site, we use a
            log file to automatically collect your IP address, the type of web
            browser you use, the type of device you use, your device’s operating
            system, your Internet Service Provider, the pages you view,
            referring and exit pages, the date and time of your visit, and the
            number of clicks to, from, and within the Site. We use this
            information to improve the performance and functionality of our Site
            and Services, and to better understand how individuals interact with
            our Site and Services. If this information is capable of being
            associated with you, directly or indirectly, we treat it as Personal
            Information. If this information is not capable of being
            individually associated with you, we treat it as Usage Data.
          </p>
          <p><b> d. Call Recording </b></p>
          <p>
            We and/or our Service Providers (as defined below), may record all
            calls and retain the content of all text messages or other
            written/electronic communications between you and us and/or our
            Service Provider, regardless of whether we make the call to you or
            you make the call to us. By communicating with us, you consent to
            such recording and retention of communications.
          </p>
          <p><b> e. Session Recording </b></p>
          <p>
            We and/or our Service Providers (as defined below), may monitor
            and/or record and retain the content of all activity, clicks, and
            keystrokes on our owned and affiliated websites for informational
            and quality control purposes. By continuing to utilize our website
            and its content, you consent to such recording and retention.
          </p>
          <p><b> 4. HOW WE COLLECT INFORMATION </b></p>
          <p><b> a. Voluntary Disclosure </b></p>
          <p>
            We may ask you to provide us with Personal Information when you
            register with the Site/Services, submit an inquiry form, complete a
            survey, communicate with us (online or offline), sign-up for a
            newsletter, and at other times. You are not required to provide us
            your Personal Information; however, if you choose not to provide the
            requested information, you may not be able to use some or all of the
            features of the Site or Services or we may not be able to fulfill
            your requested interaction. &nbsp;
          </p>
          <p><b> b. Third-Party Data Sources </b></p>
          <p>
            We collect Personal Information from third-party data sources such
            as insurance agents, insurers, Insurance Services, consumer
            reporting companies, and data brokerage companies. This Personal
            Information may be appended to Personal Information we have already
            collected from or about you.
          </p>
          <p id="cookies-and-technologies"><b> c. Cookies </b></p>
          <p>
            We use cookies (a small text file placed on your computer to
            identify your computer and browser) to improve the experience of the
            Site and Services, such as saving your preferences from visit to
            visit to present you with a customized version of the Site. Many web
            browsers are initially set up to accept cookies. You can reset your
            web browser to refuse all cookies or to indicate when a cookie is
            being sent. However, certain features of the Site or Services may
            not work if you delete or disable cookies.
          </p>
          <p>We use the following types of cookies:</p>
          <ul>
            <li>
              Essential Cookies. These Cookies are required for the operation of
              our Sites (for example, to enable you to log into secure areas of
              our website or use a shopping cart). These Cookies enable you to
              move around the Sites and use their features. Disabling these
              Cookies will encumber the Sites’ performance and may make the
              features and service unavailable.
            </li>
            <li>
              Session Cookies: Session cookies keep track of you or your
              information as you move from page to page within the Site and are
              typically deleted once you close your browser.
            </li>
            <li>
              Persistent Cookies: Persistent cookies reside on your system and
              allow us to customize your experience if you leave and later
              return to the Site/Services. For example, persistent cookies may
              allow us to remember your preferences.
            </li>
            <li>
              Analytics and Customization Cookies: Analytics cookies help us
              understand how our Site is working and who is visiting our Site.
              We may use these types of Cookies to ensure that visitors can
              easily find the information they are looking for on our websites.
              Google Analytics is one tool we use and you can learn more by
              reviewing Google’s
              <a target="_blank" href="https://policies.google.com/privacy?fg=1">Privacy Policy</a>.
            </li>
            <li>
              Functionality Cookies: These Cookies are used to recognize you
              when you return to our Sites. This enables us to personalize our
              content for you, greet you by name, and remember your preferences
              (for example, your choice of language or region).
            </li>
            <li>
              Advertising Cookies: These Cookies record your online activities,
              including your visits to our Sites, the pages you have visited,
              and the links and advertisements you have clicked. One purpose of
              this is to help make the content displayed on our Sites more
              relevant to you. Another purpose is to allow us and our service
              providers to deliver advertisements or other communications to you
              that are designed to be more relevant to your apparent interests.
              When we and our service providers do so, this also involves
              partners such as publishers, data management platforms, and
              demand-side platforms that help to manage the data. For example,
              if you look at one page on one of our Sites, we may cause an
              advertisement to be delivered to you, on our Sites, or on other
              sites, for products referenced on that page or for similar
              products and services. We and our service providers and partners
              may also append other data to information collected by these
              Cookies, including information obtained from third parties, and
              share this information with third parties for the purpose of
              delivering ads to you.
            </li>
          </ul>
          <p>
            Depending on the circumstances, we may use cookies to collect
            Personal Information, Usage Data, or both.
          </p>
          <p>
            Some cookies may be placed by third parties such as Google,
            Facebook, Twitter, or LinkedIn. Personal Information collected by
            third-party cookies is governed by each third-party’s privacy
            policy.
          </p>
          <p>
            Your acceptance of cookies is not required for visiting our website.
          </p>
          <p>
            Most internet browsers accept cookies automatically. If you do not
            wish this to be so, you may set up your browser so that it will
            inform you about the setting of cookies so that you may permit this
            only in individual cases; or that you refuse to accept cookies for
            certain cases or on a general basis; or that you activate the
            automatic deletion of the cookies when you close the browser. In
            addition, you may at any time delete cookies that have already been
            set by using an internet browser or other software programs.
          </p>
          <p>
            Many devices and browsers give you the option to manage and/or
            disable cookies and other online tracking technologies. To opt-out
            of the use of these technologies, you can change your browser
            options to stop accepting cookies or to prompt you before accepting
            cookies. To assist you, we compiled directions for some commonly
            used browsers and devices in the table below. Keep in mind that not
            all devices and browsers are the same so the methods described below
            may not work for your browser, browser version, or device and may
            change at any time. We encourage you to refer to the relevant
            instructions for your particular device or browser.
          </p>
          <p>
            Before you dive in, please note, if you opt out of the collection
            and use of information via cookies and other tracking technologies,
            you may still see customized advertising displayed by advertising
            networks from which you have not opted out. In addition, your
            opt-out will not prevent any advertising network from displaying
            advertising that is not customized to you and your interests and,
            consequently, may be less relevant to you. You will need to make
            such choices on each browser and device you may use to visit our
            websites and webforms.
          </p>
          <ul>
            <li>
              <b>Disabling Cookies in Browsers.</b> When you elect to opt-out on
              a website, including our websites, an opt-out cookie is placed on
              your device. Then when you visit the same website, the opt-out
              cookie tells the website you opted out. This means that blocking
              all cookies may then prevent the websites from operating as
              expected and may also prevent your consent choices from being
              stored. This may mean that if you opt-out and then block cookies,
              we may not know about, or be able to honor, your opt-out. You
              should also be aware that blocking cookies on your computer will
              not affect your consent choices on a different device, such as a
              mobile device. You may want to keep this in mind when determining
              your browser cookie settings. For more information on how to
              disable cookies on the most common browsers see below.
            </li>
            <li>
              <b> Google Chrome (Google Support): </b>
              <ol>
                <li>At the top right, click “⋮” icon, and then Settings.</li>
                <li>At the bottom, click Advanced.</li>
                <li>
                  Under “Privacy and security”, click Site settings and then
                  Cookies.
                </li>
                <li>
                  Turn Allow sites to save and read cookie data on or off.
                </li>
              </ol>
            </li>
            <li>
              <b>Apple Safari (Apple Support):</b>
              <ol>
                <li>
                  On Mac:
                  <ul>
                    <li>Safari &gt; Preferences, click “Privacy”</li>
                  </ul>
                </li>
                <li>
                  On iOS:
                  <ul>
                    <li>
                      Settings &gt; Safari, then turn on “Block All Cookies”
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <b>Mozilla Firefox (Mozilla Support):</b>
              <ol>
                <li>Click the menu button “☰” and select “Preferences”.</li>
                <li>
                  Select the “Privacy &amp; Security” panel and go to the
                  “Cookies and Site Data” section
                </li>
              </ol>
            </li>
            <li>
              <b>Microsoft Edge (Microsoft Support):</b>
              <ol>
                <li>More button “⋯” &gt; Settings.</li>
                <li>Select “View Advanced Settings”.</li>
                <li>
                  Under “Privacy and security”, click Site settings and then
                  Cookies.
                </li>
                <li>Under “Privacy and Services” &gt; “Cookies”</li>
              </ol>
            </li>
          </ul>
          <p>
            <b>Other Options for Disabling Online Tracking.</b> Blocking cookies
            in your browser may not prevent cross-site tracking of your online
            activity using other online tracking technologies. In addition to
            managing your cookie settings for your browser, you can opt-out of
            tracking directly with the businesses that operate the tracking
            technologies. To opt-out of certain analytic tracking by our service
            providers visit the links below:
          </p>
          <p><b>Helpful Links:</b></p>
          <ul>
            <li>Google Analytics: https://tools.google.com/dlpage/gaoptout</li>
            <li>
              Bing:
              https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads,
              http://choice.microsoft.com/
            </li>
          </ul>
          <p>
            <b>Do Not Track.</b> Some web browsers may transmit “do not track”
            signals (go here to learn more). Web browsers may incorporate or
            activate these features differently, making it unclear if users have
            consciously activated them. As a result, we do not take steps to
            respond to such signals.
          </p>
          <p><b> d. Online Behavioral Advertising </b></p>
          <p>
            We may work with third-party advertisers, search providers, and ad
            networks (“Advertisers”) to learn more about you and show you ads or
            other content that we believe would be relevant to you. Advertisers
            may collect and use information about your use of the Site or
            Services as well as other websites and services. These companies may
            use cookies and other online tracking technologies to collect and
            use your information. We and/or Advertisers may also append other
            data to the data collected by Advertisers to create an interest
            profile of individual users. We try to retain and analyze data in a
            way that anonymizes it, but it is possible that we may collect or
            use Personal Information as part of our online behavioral
            advertising activities. Our Privacy Policy does not cover any use of
            information by an Advertiser. Advertisements may be shown via the
            Site, the Services, or on third-party websites. You can visit the
            Network Advertising Initiative website at
            <a href="http://www.networkadvertising.org" target="_blank">
              www.networkadvertising.org
            </a>
            or the Digital Advertising Alliance’s website at
            <a href="http://www.digitaladvertisingalliance.org" target="_blank">
              www.digitaladvertisingalliance.org
            </a>
            to learn more about Internet advertising companies and how to opt
            out of their information collection.
          </p>
          <p>
            <b> e. Information from Advertisements and Insurance Services </b>
          </p>
          <p>
            If you arrive at the Site via an advertisement (e.g., banner ad) or
            an Insurance Service, we may collect information regarding the
            advertisement or Insurance Service with which you interacted and
            your interactions (e.g., item clicked, date and time)
          </p>
          <p><b> f. Tracking Pixels and Clear GIFs </b></p>
          <p>
            We employ software technology that enables us to track certain
            aspects of a user's visit to the Site. This technology helps us
            better manage content on the Site by informing us what content is
            effective, how consumers engage with the Site, and how consumers
            arrive at and/or depart from the Site. The software typically uses
            two methods to track user activity: (1) "tracking pixels" and (2)
            "clear gifs." Tracking pixels are pieces of executable code that are
            embedded in a web page that track usage activity including which
            pages are viewed, when they are viewed, and how long the pages are
            viewed. Clear gifs are tiny graphics with unique identifiers which
            are embedded in web pages and email messages that track whether or
            not a user has viewed a particular web page or email message. User
            activity information may be associated with additional information
            about a user's session, such as IP addresses and the type of browser
            used, and Personal Information, if provided by the user.
          </p>
          <p>
            If you arrive at the Site by "clicking through" from another
            website, then certain information about you that you provided to
            that other website, such as the terms you were searching that led
            you to the Site, may be transmitted to us and we may use it. You
            should review the privacy policy of any website from which you
            reached the Site to determine what information was collected by that
            website and for what purpose(s) you agreed that website could use
            that information. We may retain information about you provided to us
            by other websites, and will only use it in accordance with this
            Privacy Policy. Such information may be associated with other Usage
            Data or Personal Information.
          </p>
          <p><b> g. Google Analytics Advertising Features </b></p>
          <p>
            We utilize certain Google Analytics tools to improve the Site
            experience for our users, but also to aggregate the characteristics
            and browsing habits of our users, on an anonymized basis, to provide
            products and services that are more tailored to our users.
          </p>
          <p><b> h. Social Media Widgets </b></p>
          <p>
            The Site may include social media features, such as the Facebook,
            YouTube, LinkedIn, and Twitter widgets. These features may collect
            information about your IP address and the pages you visit on our
            Site as well as other Personal Information. A cookie may be set to
            ensure that a feature properly functions. Your interactions with
            social media features are governed by the privacy policies of the
            companies that provide them.
          </p>
          <p><b> 5. HOW WE USE AND SHARE INFORMATION </b></p>
          <p><b> a. Generally </b></p>
          <p>We use Personal Information for internal purposes, such as to</p>
          <ul>
            <li>Provide you with the Site and Services;</li>
            <li>Process or complete transactions you requested;</li>
            <li>
              Improve the Site and Services, including customization and
              personalization;
            </li>
            <li>
              Communicate with you Notify you of our or third-party products and
              services via mail, email, telephone, and/or text messaging;
            </li>
            <li>Market our or third-party products and services to you;</li>
            <li>
              Communicate with you about the Site, the Services, and other
              matters via mail, email, telephone and/or text messaging;
            </li>
            <li>Perform marketing and non-marketing analytics;</li>
            <li>Operate information security and anti-fraud programs;</li>
            <li>
              Perform day-to-day business operations such as accounting,
              bookkeeping, and legal compliance;
            </li>
            <li>Respond to governmental and other legal requests; and</li>
            <li>
              Compile information and analyses to enhance the customer
              experience and improve our business.&nbsp;
            </li>
            <li>
              Develop, operate, improve, deliver, maintain and proetect our
              services, including webistes and webforms.
            </li>
            <li>
              Enhance our services and your experience with them by using
              information we collect automatically by cookies and other
              technologies.
            </li>
          </ul>
          <p><b> b. Testimonials </b></p>
          <p>
            If you provide a testimonial, we may post it publicly on the Site or
            in other advertising material. By providing a testimonial, you give
            us permission to use it in any manner and for any purpose, including
            in marketing and advertising communications, without compensation to
            you.
          </p>
          <p><b> c. Service Providers </b></p>
          <p>
            From time to time, we may establish a relationship with other
            businesses that we believe are trustworthy and that we believe have
            privacy practices consistent with ours that may include corporate
            affiliates (“Service Providers”). We contract with Service Providers
            to provide certain services to us, including:
          </p>
          <ul>
            <li>Online hosting and maintenance;</li>
            <li>Marketing and promotion design and management;</li>
            <li>Facilitating communications;</li>
            <li>Payment processing;</li>
            <li>Collection of accounts;</li>
            <li>Management of access to services;</li>
            <li>Business analytics (marketing and non-marketing);</li>
            <li>
              IT and network administration such as data storage and management,
              website hosting, and data security;
            </li>
            <li>Professional services as legal and accounting;</li>
            <li>Identity and contact information validation; and</li>
            <li>
              Day-to-day business operations such as courier services,
              facilities management, and document destruction vendors.&nbsp;
            </li>
          </ul>
          <p>
            We only provide our Service Providers with the information necessary
            for them to perform these services on our behalf. Each Service
            Provider is expected to use reasonable security measures appropriate
            to the nature of the information involved to protect your Personal
            Information from unauthorized access, use, or disclosure. Service
            Providers are prohibited from using Personal Information other than
            as specified by us. We share the following types of Personal
            Information with one or more Service Providers: personal
            identifiers; characteristics of protected classification; contact
            information; commercial information; financial account information;
            consumer reports; internet or other electronic network activity;
            professional or employment-related information; education
            information; health information; and inferences drawn from the above
            categories of Personal Information that relate to your preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </p>
          <p><b> d. Third-Party Selling </b></p>
          <p>
            We sell your Personal Information to third-party companies who want
            to advertise and market products or services likely to be of
            interest to you, or to provide you with special offers and
            opportunities because of your relationship with us. For example, we
            may sell your Personal Information to insurance agents who may be
            able to provide the type of insurance you are interested in
            obtaining or Insurance Services that may obtain and present
            additional insurance options. In the past twelve months we have sold
            the following types of Personal Information with one or more
            third-party companies for their marketing purposes: personal
            identifiers; characteristics of protected classification; contact
            information; commercial information; financial account information;
            consumer reports; internet or other electronic network activity;
            professional or employment-related information; education
            information; health information; and inferences drawn from the above
            categories of Personal Information that relate to your preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </p>
          <p>
            The information we share with advertisers and other third parties
            and the information you provide directly to such advertisers and
            other thirdparties is subject to each advertiser’s or other third
            party’s privacy policy. Please read each advertiser or third party’s
            policgty to learn more information about their collection practices
            and the choices ou can make about how they collect and use your
            personal information. Please note, an advertiser may keep the
            information we share witht hem (that you provided to us through our
            websites and webforms) whether or not you elect to purchase their
            services. The agreements we have with those advertsers and third
            parties that partner with us require them to secure any personal
            information (personal data) we provide them, limit how they use such
            information, and comply with nationa, state and other applicable
            privacy regulations. Data obtained through the short code program
            will not be shared with any third-parties for their marketing
            purposes.
          </p>
          <p><b> e. Email Marketing </b></p>
          <p>
            We may use your Personal Information to market products or services
            likely to be of interest to you, or to provide you with special
            offers and opportunities, via email. With respect to commercial
            email communications from us, you may opt out of these emails via a
            link in the footer of all commercial email messages.
          </p>
          <p><b> 6. SECURITY </b></p>
          <p>
            We recognize the importance of safeguarding the confidentiality of
            your Personal Information. Accordingly, we employ commercially
            reasonable administrative, technical, and physical safeguards to
            protect your Personal Information from unauthorized access,
            disclosure, and use. Even with these safeguards, no data
            transmission over the Internet or other network can be guaranteed
            100% secure. As a result, while we strive to protect information
            transmitted on or through our Site and Services we cannot, and do
            not, guarantee the security of any information you or we transmit on
            our Site or using our Services, or that you or we transmit over any
            other electronic network and you accept any associated risk.
          </p>
          <p><b> 7. BLOGS &amp; FORUMS </b></p>
          <p>
            Our Site and/or Services may contain features such as blogs, forums,
            message boards, or other public-facing message systems. If you use
            one of these features, your comments, including any Personal
            Information you share, will be viewable to other users and the
            public. You use such features at your own risk. We are not
            responsible for the Personal Information you share using such
            features or any consequences that result therefrom.
          </p>
          <p>
            We reserve the right, but are under no obligation, to remove any
            comments from such features that we believe, in our sole discretion,
            constitute defamation, libel, slander, obscenity, pornography,
            profanity, hate speech, discrimination, or otherwise offend other
            users or our brand.
          </p>
          <p><b> 8. OTHER WEBSITES </b></p>
          <p>
            Please be aware that third-party websites accessible through the
            Site or Services may have their own privacy and data collection
            policies and practices. We are not responsible for any actions,
            content of websites, or privacy policies of such third parties. You
            should check the applicable privacy policies of those third parties
            when providing information through linked websites.
          </p>
          <p><b> 9. COMPLIANCE WITH LAWS AND LAW ENFORCEMENT </b></p>
          <p>
            We cooperate with government and law enforcement officials and
            private parties to enforce and comply with the law. We may disclose
            Personal Information, Usage Data, and any other information about
            you to government or law enforcement officials or private parties
            if, in our sole discretion, we believe it is necessary or
            appropriate to respond to legal requests (including court orders,
            investigative demands, and subpoenas), to protect the safety,
            property, or rights of ourself, users, or any other third party, to
            prevent or stop any illegal, unethical, or legally actionable
            activity, or to comply with law.
          </p>
          <p><b> 10. OTHER TRANSFERS </b></p>
          <p>
            We may share Personal Information and Usage Data with businesses
            controlling, controlled by, or under common control with us. If we
            are merged, acquired, or sold, or in the event of a transfer of some
            or all of our assets, we may disclose or transfer Personal
            Information and Usage Data in connection with such transaction.
          </p>
          <p><b> 11. HOW LONG WE RETAIN YOUR DATA </b></p>
          <p>
            We retain your Personal Information for as long as we have a
            relationship with you. We also retain your Personal Information for
            a period of time after our relationship with you has ended where
            there is an ongoing business need to retain it. This includes
            retention to comply with our legal, regulatory, tax, and/or
            accounting obligations. Generally, we retain Personal Information
            until you ask us to delete it, or otherwise in accordinace with
            applicable law and our internal data retention policies
          </p>
          <p><b> 12. CHILDREN’S PRIVACY </b></p>
          <p>
            The Site is not intended for children under the age of 18 and we do
            not knowingly collect Personal Information from children under the
            age of 18. If we become aware that we have inadvertently received
            Personal Information from a child under the age of 18, we will
            delete such information from our records.
          </p>
          <p><b> 13. OPT OUT </b></p>
          <p>
            You have the right to opt out of receiving marketing from us by
            emailing us at
            <a target="_blank" href="mailto:media@findmedicarechoice.com">media@findmedicarechoice.com</a>
            If you opt out of marketing you will still receive transactional
            messages, such as responses to your questions. If you opt-out of
            receiving marketing communications from our business partners with
            whom we have shared your information, you will still receive
            marketing communications from us and any other business partners
            with whom your information was shared. If you do not wish us to
            track your location, you can turn off the GPS location or push
            notifications on your phone or personal computer through your local
            settings.
          </p>
          <p><b> 14. CONTACT US </b></p>
          <p>
            If you have any questions about our Site, Services, or this Privacy
            Policy, please email us at
            <a target="_blank" href="mailto:media@findmedicarechoice.com">media@findmedicarechoice.com</a>.
          </p>
          <p><b> 15. EUROPEAN VISITORS </b></p>
          <p>
            In connection with the General Data Protection Regulation (GDPR)
            applicable in the European Economic Area (EEA), we eliminate or
            limit the use of cookies and similar technologies with visitors from
            the EEA (excluding our corporate website available at
            FindMedicareChoice.com, which continues to use cookies for all users unless
            opted out (see more information below)). Specifically:
          </p>
          <ul>
            <li>
              If you visit a third-party site that uses our services, we will
              not place any cookies on your device unless you have provided your
              consent and were provided with notice of our privacy practices and
              your ability to opt-out at any time.
            </li>
            <li>
              We will not seek your consent to use cookies in this instance,
              though the advertiser or their website publisher may do so.
            </li>
            <li>
              If you visit one of our websites, we will only place limited
              third-party cookies, such as those made available for analytics
              purposes by Google and Bing. In this case, we will only add these
              after you indicate your consent. If you do not consent, then no
              cookies will be written to your device.
            </li>
            <li>
              If you do not consent or opt out, you will still be able to use
              our websites, but certain functionality may be limited. To enable
              this feature, we use third-party tools to determine your
              geographic location; if these tools indicate that you are in the
              EEA, then we treat your visit as above. If you feel that you are
              subject to GDPR, but we have nonetheless used cookies in
              connection with your visit without consent (which may be due to a
              failure of the third party geolocation tools, your use of a proxy
              server, your location outside of the EEA at the time of your
              visit, or some other factor beyond our control) please notify us
              at
              <a target="_blank" href="mailto:media@findmedicarechoice.com">media@findmedicarechoice.com</a>
              and make sure to include any relevant details so that we can
              ensure proper treatment of your data under GDPR.
            </li>
          </ul>
          <p id="special-notice-california">
            <b> 16. ADDITIONAL CALIFORNIA CONSUMER RIGHTS </b>
          </p>
          <p>
            The California Consumer Privacy Act ("CCPA") gives California
            residents certain rights to access and delete their "Personal
            Information," and to opt out of the sale of their Personal
            Information. The CCPA also requires businesses to disclose in their
            privacy policies information about how they collect, use, and share
            Personal Information. This section of the privacy policy contains
            the disclosures we are required to make and summarizes the rights
            that California residents have under the CCPA.
          </p>
          <p>
            For purposes of this section, the term "Personal Information" means
            Personal Information under Cal. Civ. Code 1798.140(o) as amended
            from time to time. To the extent that any other provision of this
            Privacy Policy conflicts with a provision of this section, the
            provision in this section controls as to California residents.
          </p>
          <p><b> a. Right to Know </b></p>
          <p>
            You have the right to request that we provide you (i) the categories
            and/or specific pieces of Personal Information we collected about
            you; (ii) the categories of sources from which your Personal
            Information was collected; (iii) the business or commercial purpose
            for which we collected your Personal Information; and (iv) the
            categories of third parties with whom we shared your Personal
            Information. If we sold or disclosed your Personal Information to
            third parties, you also have the right to request that we provide
            you (i) the categories of Personal Information we collected about
            you; (ii) the categories of your Personal Information we sold to
            third parties; (iii) the categories of third parties to whom we sold
            your Personal Information; and (iv) the categories of your Personal
            Information we disclosed to third parties.
          </p>
          <p>
            Please note that we are not permitted to provide access to specific
            pieces of Personal Information if the Personal Information is
            sensitive or creates a high risk of potential harm from disclosure
            to an unauthorized person such as financial account information,
            health information, social security numbers, or driver’s license
            numbers. If we withhold specific personal information because of its
            sensitivity or risk, we will inform you.
          </p>
          <p>
            <b>Categories of Information Collected, Disclosed or Sold in the
              Past 12 Months</b>
          </p>
          <p>
            The below table discloses the categories of Personal Information
            that we have collected in the past 12 months from the effective date
            of this Privacy Policy. For each category of Personal Information,
            the table identifies:
          </p>
          <p>
            Whether we have sold to a third party, or disclosed for a business
            purpose, the category of Personal Information; and the categories of
            third parties to whom the information was disclosed or sold. The
            table does not include Personal Information provided to us by
            partners or other customers in a business-to-business context.
          </p>
          <table class="table table-striped">
            <thead class="thead-light">
              <tr>
                <th>Category of Personal Information Collected</th>
                <th>Sold or Disclosed?</th>
                <th>
                  Category of Third Parties to Whom the Personal Information was
                  Sold or Disclosed
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Identifiers</b>, including your name, name, IP address,
                  email address, phone, address
                </td>
                <td>Disclosed</td>
                <td>
                  We have disclosed for a business purpose this category of
                  Personal Information to Service Providers
                </td>
              </tr>
              <tr>
                <td>
                  <b>Customer records information</b>, including signature, bank
                  account number, address,
                </td>
                <td>Disclosed</td>
                <td>
                  We have disclosed for a business purpose this category of
                  Personal Information to Service Providers
                </td>
              </tr>
              <tr>
                <td><b> Characteristics of Protected Classifications </b></td>
                <td>Not Sold or Disclosed</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Commercial Information</b>, including records of services
                  that you have purchased in the past
                </td>
                <td>Disclosed</td>
                <td>
                  We have disclosed for a business purpose this category of
                  Personal Information to Service Providers
                </td>
              </tr>
              <tr>
                <td>
                  <b>Internet or Other Network Activity Information</b>,
                  including information about how you interact with our website
                </td>
                <td>Disclosed</td>
                <td>
                  We have disclosed for a business purpose this category of
                  Personal Information to Service Providers
                </td>
              </tr>
              <tr>
                <td><b>Geolocation Data</b></td>
                <td>Not sold or Disclosed</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Professional or Employment-Related Information</b>,
                  including your job title and organizational affiliation
                </td>
                <td>Disclosed</td>
                <td>
                  We have disclosed for a business purpose this category of
                  Personal Information to Service Providers
                </td>
              </tr>
            </tbody>
          </table>
          <p><b> b. Right to Deletion </b></p>
          <p>
            You have the right to request that we delete any Personal
            Information we have collected about you. Please understand that we
            are not required to honor a deletion request if we need the
            information to complete a requested or reasonably anticipated
            transaction, prevent security incidents or fraud, enable internal
            uses that are reasonably aligned with your expectations, comply with
            legal obligations, or perform other functions permitted by law.
          </p>
          <p><b> c. Exercising your Rights to Know and Deletion </b></p>
          <p>
            You may exercise your Rights to Know and Deletion by completing the form on our
            <a href="/ccpa-opt-out">CCPA Request Page</a>. Please be specific in
            your request and provide sufficient information to allow us to
            locate your Personal Information in our systems based on your
            request. We will retain a record of your request, including a
            deletion request, in accordance with law. Additional information
            about our timeframe for response and identity verification is
            available on our <a href="/ccpa-opt-out"> our CCPA Request Page</a>.
          </p>
          <p>
            Request information about our collection, use, and sharing of their
            information (a "Request to Know"), including:
          </p>
          <p>
            the categories and specific pieces of Personal Information we have
            collected about them;
          </p>
          <p>
            the categories of sources from which we have collected their
            Personal Information;
          </p>
          <p>
            the business or commercial purpose for collecting or selling their
            Personal Information;
          </p>
          <p>
            the categories of their Personal Information that we have sold; and
          </p>
          <p>
            the categories of third parties with whom we have shared, disclosed
            for a business purpose, or sold their Personal Information, and
            which categories of Personal Information we have sold to which
            categories of third parties.
          </p>
          <p>
            We will not discriminate against users for exercising these rights.
            We may charge users different prices or provide different levels or
            quality of goods or services, if the difference is reasonably
            related to the value of their data. We may also offer financial
            incentives for the collection, sale, or deletion of your Personal
            Information. In addition, some Personal Information is essential for
            us to provide the Services. For example, we cannot provide you with
            an insurance quote without first collecting some Personal
            Information about you. In such instances, your refusal to provide
            the necessary Personal Information will result in the inability to
            use our Service.
          </p>
          <p>
            You may exercise your Rights to Know and Deletion by completing the form on our
            <a href="/ccpa-opt-out"> CCPA Request Page</a>. Please be specific
            in your request and provide sufficient information to allow us to
            locate your Personal Information in our systems based on your
            request. We will retain a record of your request, including a
            deletion request, in accordance with law. Additional information
            about our timeframe for response and identity verification is
            available on <a href="/ccpa-opt-out"> our CCPA Request Page</a>.
          </p>
          <p><b> d. Right to Opt Out, Correct Inaccuracies </b></p>
          <p>
            You have the right to direct us not to sell your Personal
            Information to third parties. You may exercise your Right to Opt Out
            by completing the form on
            <a href="/ccpa-opt-out"> our CCPA Request Page</a>. Please provide
            sufficient information to allow us to locate your Personal
            Information in our systems.
          </p>
          <p>
            Please contact us at
            <a target="_blank" href="mailto:media@findmedicarechoice.com">media@findmedicarechoice.com</a>
            if you would like to request access to the PII that we have
            collected online from you, or to correct factual errors in such
            information.
          </p>
          <p><b> e. Non-Discrimination Notice </b></p>
          <p>
            We will not discriminate against you for exercising your rights
            under the CCPA or this Privacy Policy.
          </p>
          <p><b> f. California “Do Not Track” Disclosure </b></p>
          <p>
            Do Not Track is a web browser privacy preference that causes the web
            browser to broadcast a signal to websites requesting that a user’s
            activity not be tracked. At this time, our Site and Services do not
            respond to “do not track” signals.
          </p>
          <p><b> g. California Site Ownership Disclosure </b></p>
          <p>
            Under California Civil Code Section 1789.3, California residents are
            entitled to the following information: The provider of the Site is
            Brightsight Media Inc. To file a complaint regarding the Site or to
            receive further information about the Site, please contact us.
          </p>
          <p><b>h. Right to Non-Discrimination</b></p>
          <p>
            Should you wish to exercise your rights as a resident of California,
            we will not discriminate against you by showing you different
            products or services based solely upon your request. Kindly note,
            however, if the advertisers require additional data or information
            from you before providing quotes, rates, or other information,
            exercising your California rights may limit the number and range of
            advertisers who are displayed in response to your request.
          </p>
          <p><b>i. Do Not Sell My Personal Information</b></p>
          <p>
            Some of our sharing activities may be a “sale” of personal
            information under California law. For example, when we share
            information we collected automatically from you with advertisers in
            order to create consumer profiles and present our advertisers’
            products and services to you. Subject to certain exceptions, as a
            California resident, you may opt-out of the “sale” of your personal
            information at any time by taking the following actions:
          </p>
          <ul>
            <li>
              For information collected automatically through cookies and
              similar tracking technologies change your browser settings to stop
              accepting cookies or to prompt you before accepting cookies. To
              learn more, read Cookies and Other Technologies.
            </li>
            <li>
              For all other information, if you wish to put on file with us your
              preference to opt-out of our sharing of your personal Information
              with third parties for such third parties’ direct marketing
              purposes or to find out more about your opt-out rights, please
              submit your request through our Do Not Sell My Personal
              Information form.
            </li>
          </ul>
          <p><b> j. Shine the Light </b></p>
          <p>
            California residents may, no more than once per calendar year,
            request from us information about our disclosure of certain
            categories of personal information to third parties for direct
            marketing purposes. To be valid, requests must be submitted to us at
            in our Privacy Center and select the “Shine the Light Request”
            option from the drop down menu. We will provide you within 30 days
            of receiving your request, a list of the categories of personal
            information (also known as personal data) disclosed to third parties
            for direct marketing purposes during the immediately preceding
            calendar year and the names and addresses of the third parties.
          </p>
          <p><b> k. Minors </b></p>
          <p>
            Visitors under 16 years of age are not permitted to use and/or
            submit their personal information at any Site. We do not knowingly
            solicit or collect information from visitors under 16 years of age.
            We encourage parents and guardians to spend time online with their
            children and to participate in and monitor the interactive
            activities of their children. The CCPA and Children's Online Privacy
            Protection Act imposes certain requirements on website operators
            that have actual knowledge that they collect personally identifiable
            information from children under 13 years of age. If we learn that
            personally identifiable information of persons less than 13 years of
            age has been collected without verifiable parental consent, then we
            will take the appropriate steps to delete this information. To make
            such a request, or if you have any questions or concerns about the
            Privacy Policy for the website or its implementation, please contact
            us at:
            <a target="_blank" href="mailto:media@findmedicarechoice.com">media@findmedicarechoice.com</a>.
          </p>
          <p><b>17. Nevada Residents</b></p>
          <p>
            Subject to certain exceptions, some of our data sharing practices
            may be considered a “sale” of your personal information under Nevada
            Revised Statutes 603A. Nevada residents may opt-out of the “sale” of
            personal information covered under the statute, by exercising your
            cookie consent options as detailed in Cookies and Other Technologies
            or by submitting your request through our Do Not Sell My Personal
            Information form.
          </p>
          <p><b>18. Retention of Your Personal Data.</b></p>
          <p>
            We will retain your Personal data only for as long as necessary for
            the purposes set out in this Privacy Policy and to comply with our
            legal obligations, resolve disputes and enforce our legal agreements
            and policies. We will also retain Usage Data for internal analysis
            purposes.
          </p>
        </div>
      </el-container>
    </el-main>
  </div>
</template>

<script>
import { Container } from "element-ui";
Vue.use(Container);
export default {
  name: 'PrivacyStatement',
  components: {
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";
@import "../assets/scss/text.scss";
</style>
